export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Fagenta',
    description:
      'FAgenta is the ultimate companion for football enthusiasts around the globe. Boasting a comprehensive database of footballs most storied leagues, FAgenta provides fans with in-depth historical records of league triumphs, offering a window into the past to relive the glory days of their favorite teams.',
    description2:
      'But FAgenta doesnt just dwell in the past. The platform celebrates the present with detailed profiles of the sports most celebrated players. Fans can immerse themselves in the careers of footballs elite, from their humble beginnings to their rise to the top of the world stage.',
    description3:
      'Moreover, FAgenta is your frontline source for the latest developments in the football world. Stay updated with real-time news, behind-the-scenes insights, and expert analysis, ensuring youre always in the loop with the current happenings in football leagues across the globe.',
    description4:
      'Whether youre a statistician seeking historical data, a fan tracking the progress of rising stars, or simply seeking the latest football news, FAgenta is the gateway to the heart-pounding excitement of football. Join the community and be part of the conversation with FAgenta, where footballs legacy and present converge.',
    buttonLabel: 'Get Started Now!',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Real time Sport News',
    description:
      "Stay in the game with real-time sports news and event updates, ensuring you catch every pivotal moment.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Main Features',
    headline:
      'Event Details',
    description:
      "Instantly fetch event details, schedules, and real-time results.",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img2.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: 'Main Features',
    headline: 'Multiple Sports',
    description:
      'Covering a spectrum of sports and leagues – football, basketball, tennis, baseball, and more.',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Personalized Contents',
    description:
      "Tailored experience – personalized recommendations based on your interests.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Ultimate companion',
    headline:
      'DribblePro',
    description:
      "For sports enthusiasts, event aficionados, and anyone craving real-time event updates. Whether it's the latest game progress or instant event results, DribblePro is your ultimate smart companion!",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img5.png'),
    alt: 'Vault',
    start: 'true'
  };