export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Fagenta',
    description:
      'FAgenta is the ultimate companion for football enthusiasts around the globe. Boasting a comprehensive database of footballs most storied leagues, FAgenta provides fans with in-depth historical records of league triumphs, offering a window into the past to relive the glory days of their favorite teams.',
    description2:
      'But FAgenta doesnt just dwell in the past. The platform celebrates the present with detailed profiles of the sports most celebrated players. Fans can immerse themselves in the careers of footballs elite, from their humble beginnings to their rise to the top of the world stage.',
    description3:
      'Moreover, FAgenta is your frontline source for the latest developments in the football world. Stay updated with real-time news, behind-the-scenes insights, and expert analysis, ensuring youre always in the loop with the current happenings in football leagues across the globe.',
    description4:
      'Whether youre a statistician seeking historical data, a fan tracking the progress of rising stars, or simply seeking the latest football news, FAgenta is the gateway to the heart-pounding excitement of football. Join the community and be part of the conversation with FAgenta, where footballs legacy and present converge.',
    buttonLabel: '下载！',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: '信用卡',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '关键时刻',
    description:
      "通过实时体育新闻和事件更新，保持紧跟比赛，确保您捕捉到每一个关键时刻。",
    buttonLabel: '了解更多',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '主要特点',
    headline:
      '赛事详情',
    description:
      "立即获取事件详情、赛程和实时结果。",
    // buttonLabel: '查看详情',
    imgStart: 'start',
    img: require('../../images/img2.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '主要特点',
    headline: '多元化',
    description:
      '涵盖各种运动和联赛 – 足球、篮球、网球、棒球等。',
    buttonLabel: '立即注册',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '个性化推荐',
    description:
      "定制体验  – 根据您的兴趣提供个性化推荐。",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '终极智能伴侣',
    headline:
      'DribblePro',
    description:
      "对于体育爱好者、事件迷和渴望即时事件更新的任何人来说，无论是最新的比赛进展还是即时事件结果，DribblePro都是您的终极智能伴侣",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img5.png'),
    alt: 'Vault',
    start: 'true'
  };